<template>
<div>
    <Partials message="Página no encontrada"/>
</div>
</template>

<script>
import Partials from '@/views/student/constants/errors/partials/button.vue'

export default {
    components: {
    Partials,
  },
}
</script>
